import addToMailchimp from 'gatsby-plugin-mailchimp';
import React, { useState } from 'react';
// import * as styles from './EmailListForm.module.scss';

const EmailListForm = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email)
      .then(data => {
        if (data.result === 'success') {
          alert('Thanks for signing up!');
        }

        console.log(data.result);
      })
      .catch(() => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col rounded-lg w-1/3 bg-gray-200 text-gray-800 font-sans p-8">
      <h2 className="mt-0 mb-4 text-gray-600">Subscribe to our email list!</h2>
      <div className="flex flex-row">
        <input className="text-gray-800 border-0 w-full p-3" placeholder="Email address" name="email" type="text" onChange={handleEmailChange} />
        <button className="p-3 inline-block border-0 bg-green-600 text-green-200 " type="submit">Subscribe</button>
      </div>
    </form>
  );
};

export default EmailListForm;
